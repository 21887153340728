<template>
  <div class="no-data-box">
    <div class="no-data-content">
      <img draggable="false" :src="noData" alt="" />
      <span class="no-data-text">{{ translateTitle(text) }}</span>
    </div>
  </div>
</template>
<script>
import commonMixin from "@/components/common/commonMixin.vue";
import { VUE_APP_ID, VUE_UNION_STATION } from "@/assets/js/stationSetting";

export default {
  mixins: [commonMixin],
  props: {
    text: {
      type: String,
      default: "暂无数据",
    },
  },
  data() {
    return {
      noData: "",
    };
  },
  created() {
    this.noData = require("../../assets/img/home/no-data-88.png");
  },
};
</script>
<style lang="scss">
.no-data-box {
  // height: 100%;
  // width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  min-height: 200px;
  // max-width: 976px;
  text-align: center;
  background: #fff;
  user-select: none;
  .no-data-content {
    user-select: none;
    width: 100%;
    img {
      display: flex;
      margin: 0 auto;
      width: 80px;
    }
    .no-data-text {
      color: #909399;
      line-height: 1;
      font-size: 14px;
      margin-top: 15px;
      display: block;
    }
  }
}
</style>
