<template>
  <div class="situation">
    <div class="situation-content">
      <div>
        <top-title
          :title="translateTitle('个人总览')"
          class="dashboard"
        ></top-title>
        <!-- 总览 -->
      </div>
      <div class="app-uncter-content">
        <SettingAccount
          v-if="settingAccountData.isShowDialog"
          v-bind="settingAccountData"
          :username="username"
          @onConfirm="onConfirm"
        />
        <div class="situation-list">
          <div class="snippet user-info">
            <el-avatar :size="48" :src="avatar" fit="fill">
              <img src="../../assets/img/st-icon-1-1.png" />
            </el-avatar>
            <div class="user-content">
              <p class="nickName">
                <!-- <span v-if="!nick_name">
                  {{ translateTitle("用户") }}
                </span>
                {{ nick_name ? nick_name : "--" }} -->
                {{ nick_name }}
              </p>
              <p v-if="JSON.stringify(userInfo) != '{}'">
                UID:
                {{ userInfo.userInfo ? userInfo.userInfo.bt_user_id : "--" }}
              </p>
              <div class="account-btn">
                <span
                  class="update-account"
                  @click="settingAccountData.updateShowDialog(true)"
                  >{{ translateTitle("修改账号") }}</span
                >
              </div>
            </div>
          </div>
          <div class="snippet user-assets">
            <p class="assets-title">{{ translateTitle("资产估值") }}</p>
            <div class="assets-item">
              <span class="DINPro">
                {{ assetsShow || "--" }}
                <b style="font-size: 12px; font-wight: 500">USDT</b>
              </span>
            </div>

            <div class="assets-two">
              <span>
                ≈ {{ assetsShowCurrency[systemCurrency] || "--" }}
                <b style="font-size: 12px; font-weight: 500">{{
                  systemCurrency.toUpperCase()
                }}</b>
              </span>
            </div>
          </div>

          <div class="situation-block">
            <div>
              <p class="sub-title">{{ translateTitle("安全等级") }}</p>
              <div class="sub-list">
                <div class="steps-bar" :class="'steps-bar-' + checkAuthLevel">
                  <span class="sr-left">{{ translateTitle("低") }}</span>
                  <ul>
                    <li class="sr-1"></li>
                    <li class="sr-2"></li>
                    <li class="sr-3"></li>
                  </ul>
                  <span class="sr-right">{{ translateTitle("高") }}</span>
                </div>
                <div
                  class="level-content"
                  v-if="checkAuthLevel < authLevelTotal"
                >
                  <i class="iconfont iconicon-fail" style="color: #f78700"></i>
                  <div
                    v-cloak
                    style="width: 235px; margin-left: 7px"
                    class="level-title"
                  >
                    {{ translateTitle("您的账户安全等级为")
                    }}{{ translateTitle(authLevelText[checkAuthLevel])
                    }}{{ translateTitle("级，建议完善") }}
                  </div>

                  <router-link
                    :to="routeSplicing(language, 'ucenter/safe-setting')"
                  >
                    <span class="btn-def">{{
                      translateTitle("立即完善")
                    }}</span>
                  </router-link>
                </div>
                <div
                  v-if="checkAuthLevel >= authLevelTotal"
                  style="color: #27c683"
                >
                  {{ translateTitle("您的账户安全等级为") }}
                  {{ translateTitle(authLevelText[checkAuthLevel]) }}
                  {{ translateTitle("级。") }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="assets-content">
          <div @click="onAssets" class="assets-item">
            <div class="title">
              {{ translateTitle("现货") }}
              {{ translateTitle("持仓估值") }}
            </div>
            <div class="assts-num">
              <span class="num">{{ remainMap.u_valuation }}</span>
              <span class="unit"> USDT</span>
            </div>
            <div class="totle-num">
              <span class="num">≈ {{ remainMap.all_u_valuation.usd }} USD</span>
            </div>
          </div>
          <!-- <div class="assets-item">
            <div class="title">杠杠 持仓估值</div>
            <div class="assts-num">
              <span class="num">{{marginMap.u_valuation}}</span>
              <span class="unit"> USDT</span>
            </div>
              <div class="totle-num">
              <span class="num">≈ {{marginMap.all_u_valuation.usd}} USD</span>
            </div>
          </div> -->
          <!-- <div class="assets-item">
            <div class="title">
              {{ translateTitle("合约") }}
              {{ translateTitle("持仓估值") }}
            </div>
            <div class="assts-num">
              <span class="num">{{ contractMap.u_valuation }}</span>
              <span class="unit"> USDT</span>
            </div>
            <div class="totle-num">
              <span class="num"
                >≈ {{ contractMap.all_u_valuation.usd }} USD</span
              >
            </div>
          </div> -->
          <div @click="onFinance" class="assets-item">
            <div class="title">
              {{ translateTitle("理财") }}
              {{ translateTitle("持仓估值") }}
            </div>
            <div class="assts-num">
              <span class="num">{{ financeMap.u_valuation }}</span>
              <span class="unit"> USDT</span>
            </div>
            <div class="totle-num">
              <span class="num"
                >≈ {{ financeMap.all_u_valuation.usd }} USD</span
              >
            </div>
          </div>
          <!-- <div class="assets-item">
            <div class="title">股票 持仓估值</div>
            <div class="assts-num">
              <span class="num">{{stockMap.u_valuation}}</span>
              <span class="unit"> USDT</span>
            </div>
             <div class="totle-num">
              <span class="num">≈ {{stockMap.all_u_valuation.usd}} USD</span>
            </div>
          </div> -->
        </div>

        <div class="login-content">
          <div class="filter-bar">{{ translateTitle("用户登录历史") }}</div>
          <!-- 列表 -->
          <el-table
            :data="tableData"
            class="g-table"
            v-loading="historyLoading"
          >
            <el-table-column
              :formatter="getTimeRefor"
              :label="translateTitle('登录时间')"
            ></el-table-column>
            <el-table-column
              prop="channel_product_id"
              :label="translateTitle('登录方式')"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="login_ip"
              :label="translateTitle('IP地址')"
              align="right"
            ></el-table-column>

            <!-- <el-table-column
              prop="city"
              :label="translateTitle('登录地')"
              align="center"
            ></el-table-column> -->
            <empty-table slot="empty" v-show="!historyLoading" />
          </el-table>
          <div class="common-table-pages">
            <p>
              {{ translateTitle("每页") }}
              <span>{{ pageInfo.pageSize }}</span>
              {{ translateTitle(`条，总共`) }}
              <span>{{ pageInfo.pageCount }}</span>
              {{ translateTitle("页") }}
            </p>
            <el-pagination
              class="mt20"
              background
              layout="prev, pager, next"
              :total="pageInfo.pageTotal"
              :page-size="pageInfo.pageSize"
              :current-page.sync="pageInfo.pageCurrent"
              @current-change="onPageChange"
            ></el-pagination>
          </div>
        </div>
      </div>
      <!-- 内容 -->
    </div>
  </div>
</template>
<script>
import TopTitle from "./components/Title";
import EmptyTable from "@/components/common/NoData";
import SettingAccount from "./components/SettingAccount";
import * as Account from "@/model/user/account";
import { getCoinList } from "@/model/assets/index";
// import { getUserInfo } from "model/ucenter/ucenter";
import commonMixin from "@/components/common/commonMixin.vue";
import {
  getAssetsList,
  getUserExchangeLimit,
  userLoginLogList,
} from "@/model/ucenter/assets";

import { showMessage } from "@/components/dialog/message.js";
import { reformatting } from "@/assets/js/getTime";
import { accuracy } from "@/assets/js/accuracy";
import { apiGetAssets } from "@/model/exchange/index";
import { mapActions, mapGetters } from "vuex";
import routeSplicing from "@/assets/js/routeSplicing";
import globalFun from "@/assets/js/globalFun.js";
const refreshTime = 5000;

export default {
  mixins: [commonMixin],
  data() {
    return {
      userInfo: {},
      avatar: "",
      nick_name: "",
      authLevelTotal: 2, //设置总安全级别
      authLevelText: { 1: "低", 2: "中", 3: "高" },
      exchangeLimit: {}, // 提币限额
      pageInfo: {
        pageCount: 0,
        pageTotal: 0,
        pageSize: 10,
        pageCurrent: 1,
      },
      assetsList: {},
      tableData: [],
      assetsShow: "",
      assetsShowCurrency: {},
      assetsListTimer: null,
      historyLoading: false,
      settingAccountData: {
        getMobileType: 46,
        getMailType: 45,
        isShowDialog: false,
        updateShowDialog: (val) => {
          this.settingAccountData.isShowDialog = val;
        },
        getUserInfoInit: this.getUserInfoInit,
      },
      username: "",
      voucherInterval: null,
      marginMap: {
        u_valuation: "--",
        all_u_valuation: {
          usd: "--",
        },
      },
      contractMap: {
        u_valuation: "--",
        all_u_valuation: {
          usd: "--",
        },
      },
      stockMap: {
        u_valuation: "--",
        all_u_valuation: {
          usd: "--",
        },
      },
      financeMap: {
        u_valuation: "--",
        all_u_valuation: {
          usd: "--",
        },
      },
      remainMap: {
        u_valuation: "--",
        all_u_valuation: {
          usd: "--",
        },
      },
      globalFun,
    };
  },
  async created() {
    await this.getUserInfoInit();
    this.getAssetsList();
    await this.userLoginLogList({ page: 1 });
  },
  destroyed() {
    clearInterval(this.voucherInterval);
  },
  beforeDestroy() {
    clearInterval(this.voucherInterval);
    clearInterval(this.assetsListTimer);
  },
  mounted() {
    this.loopRefreshAssets();
  },
  methods: {
    routeSplicing,
    hideSensitiveInfo(value) {
      if (/^[0-9]{11}$/.test(value)) {
        return (value + "").substr(0, 2) + "****" + (value + "").substr(-2);
      }
      if (typeof value == "string") {
        if (value.indexOf("@") > -1) {
          const sensitiveArr = value.split("@");
          return (
            sensitiveArr[0].substr(0, 2) +
            "****" +
            value.substr(-2) +
            "@" +
            sensitiveArr[1]
          );
        }
      }
      return value;
    },
    onConfirm() {
      this.getUserInfoInit();
    },
    onFinance() {
      this.$router.push(
        this.routeSplicing(this.language, "ucenter/finanManagement")
      );
    },
    onAssets() {
      this.$router.push(this.routeSplicing(this.language, "ucenter/assets"));
    },

    async getItemList() {
      const req = {
        __version: "3.4.3",
        __channel: "2",
      };
      const { status, data } = await getCoinList(req);
      if (status == 200) {
        sessionStorage.setItem("itemList", JSON.stringify(data));
      }
    },

    loopRefreshAssets() {
      this.assetsListTimer = setInterval(
        () => this.getAssetsList(),
        refreshTime
      );
    },
    getStaticLabel(num) {
      return `${(num * 1).toFixed(2)}`;
    },
    login_land() {
      return `-`;
    },
    // 获取资产列表
    getAssetsList() {
      let params = {
        type: "all",
      };
      apiGetAssets(params).then(({ status, data }) => {
        if (status === 200) {
          this.assetsShow = data.assets.u_valuation;
          this.assetsShowCurrency = data.assets.all_u_valuation;
          this.financeMap = data.finance;
          this.marginMap = data.margin;
          this.stockMap = data.stock;
          this.contractMap = data.contract;
          this.remainMap = data.remain;
        } else if (status === -188) {
          clearInterval(this.assetsListTimer);
        }
      });
    },
    // 获取用户信息
    async getUserInfoInit() {
      this.userInfo = await Account.getUserInfo();
      // this.userInfo = JSON.parse(localStorage.getItem("userData"));
      this.settingAccountData.row = {};
      const { userInfo, accountInfo } = this.userInfo;
      if (userInfo) {
        this.settingAccountData.userInfo = userInfo;

        const { user_id, nick_name, avatar } = userInfo;
        // this.nick_name = this.hideSensitiveInfo(
        //   nick_name ? nick_name : `${user_id}`
        // );
        this.nick_name =
          nick_name ||
          this.userInfo.accountInfo.username ||
          this.globalFun.userDecryption(
            this.userInfo.accountInfo.mobile,
            "mobile"
          ) ||
          this.globalFun.userDecryption(
            this.userInfo.accountInfo.email,
            "email"
          ) ||
          this.translateTitle(`用户`) + `${user_id}`;
        this.avatar = avatar ? avatar : "https://";
      }
      if (accountInfo) {
        if (accountInfo.username) {
          let str =
            accountInfo.username || accountInfo.email || accountInfo.mobile;
          this.username =
            str.length > 2 ? str.substr(0, 2) + "****" + str.substr(-2) : str;
          return;
        } else if (accountInfo.email) {
          var emailReg = /(.{2}).+(@.+)/g;
          let str = accountInfo.email;
          this.username = str.replace(emailReg, "$1****$2");
          return;
        } else if (accountInfo.mobile) {
          let str = accountInfo.mobile;
          this.username =
            str.length > 2 ? str.substr(0, 3) + "****" + str.substr(-4) : str;
        }
      }
    },

    getInitData() {},
    // 页面数据变化
    onPageChange(val) {
      this.userLoginLogList({ page: val });
    },

    // 获取 24h 内的提币限制
    getUserExchangeLimit() {
      getUserExchangeLimit().then(({ status, data, msg }) => {
        if (status === 200) {
          this.exchangeLimit = data;
        } else {
          showMessage({ message: msg ? msg : status });
        }
      });
    },
    // 用户登录记录
    async userLoginLogList(pamars) {
      pamars.page_size = this.pageInfo.pageSize;
      this.historyLoading = true;
      await userLoginLogList(pamars).then(({ status, data, msg }) => {
        if (status === 200) {
          this.tableData = data.list;
          this.pageInfo = data.pageInfo;
        } else {
          showMessage({ message: msg ? msg : status });
        }
      });
      this.historyLoading = false;
    },
    getTimeRefor(row) {
      return reformatting(+row.login_time * 1000);
    },
  },
  computed: {
    ...mapGetters({
      language: "user/language",
      systemCurrency: "user/currency",
    }),
    coinOutNum() {
      if (JSON.stringify(this.exchangeLimit) !== "{}") {
        let { coinOutNum } = this.exchangeLimit;
        return accuracy(coinOutNum, 2);
      } else {
        return "--";
      }
    },
    limitNum() {
      if (JSON.stringify(this.exchangeLimit) !== "{}") {
        let { limitNum } = this.exchangeLimit;
        return accuracy(limitNum, 2);
      } else {
        return "--";
      }
    },
    hasUser() {
      if (JSON.stringify(this.userInfo) !== "{}") {
        return true;
      }
      return false;
    },
    isLevel1() {
      return this.userInfo.userInfo &&
        this.userInfo.userInfo.level >= 1 &&
        this.userInfo.userInfo.status === 2
        ? true
        : false;
    },

    checkAuthLevel() {
      if (this.hasUser) {
        let is_open_mobile = this.userInfo.authInfo.open_mobile;
        let is_open_email = this.userInfo.authInfo.open_email;
        // let is_open_google = this.userInfo.authInfo.open_google;
        if (this.userInfo.accountInfo.type === 4) {
          //子账号
          is_open_mobile = this.userInfo.parentUserInfo.open_mobile;
          is_open_email = this.userInfo.parentUserInfo.open_email;
          // is_open_google = this.userInfo.parentUserInfo.open_google;
        }

        let check_num = 0;
        is_open_mobile === 1 && (check_num += 1);
        is_open_email === 1 && (check_num += 1);
        // is_open_google === 1 && (check_num += 1);

        check_num = (check_num / this.authLevelTotal) * 3;
        if (check_num <= 1) {
          check_num = 1;
        } else if (check_num > this.authLevelTotal) {
          check_num = 3;
        }
        return parseInt(check_num);
      } else {
        return 0;
      }
    },
  },
  components: {
    TopTitle,
    EmptyTable,
    SettingAccount,
  },
};
</script>
<style lang="scss">
.situation {
  .situation-content {
    // width: 1037px;
    // height: 400px;
    width: 100%;
    box-sizing: border-box;
    .btn-def {
      color: #fff;
      // width: 82px;
      padding: 0 10px;
      height: 30px;
      font-size: 14px;
      font-weight: 500;
      border: solid 1px #c2c7d0;
      background-color: #ffffff;
      text-align: center;
      margin-left: 10px;
      color: #354052;
      display: inline-block;
      line-height: 30px;
      cursor: pointer;
      border-radius: 3px;
      &:hover {
        border: solid 1px #c2c7d0;
        background-color: #ffffff;
        color: #354052;
      }
    }
  }
  .header {
    background-color: #fff;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
    // overflow: hidden;
    .dashboard {
      border: none;
      border-radius: initial;
    }
  }
  .app-uncter-content {
    margin: 20px;
  }
  .assets-content {
    margin-top: 20px;
    height: 118px;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
    background-color: #ffffff;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 20px;
    text-align: left;
    .assets-item {
      cursor: pointer;
      min-width: 260px;
      .title {
        user-select: none;
        height: 30px;
        color: #9aa1b1;
        font-size: 14px;
        font-weight: 500;
        line-height: 30px;
      }
      .assts-num {
        height: 30px;
        line-height: 30px;
        color: #354052;
        font-weight: 500;
        .num {
          font-size: 24px;
        }
        .unit {
          font-size: 12px;
        }
      }
      .totle-num {
        height: 14px;
        line-height: 14px;
        color: #9aa1b1;
        font-weight: 500;
        font-size: 12px;
      }
    }
  }
  .situation-list {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
    background: #ffffff;
    display: flex;
    border-radius: 4px;
    padding: 30px 20px;
    clear: both;
    height: 156px;

    .left {
      display: flex;
      align-items: center;
    }

    .snippet {
      font-size: 14px;
      text-align: left;

      &.user-info {
        p {
          color: #354052;
          font-weight: 500;
        }
        display: flex;
        .user-content {
          margin-left: 21px;
          // width: 144px;
          .nickName {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 144px;
            color: #354052;
            padding-bottom: 7px;
            font-size: 18px;
          }

          .account-btn {
            user-select: none;
            margin-top: 18px;
            .update-account {
              width: fit-content;
              height: 32px;
              padding: 5px 13px;
              border-radius: 3px;
              border: solid 1px #c2c7d0 !important;
              background-color: #ffffff;
              font-size: 14px;
              font-weight: 500;
              color: #354052;
              text-align: center;
              cursor: pointer;
              box-sizing: border-box;
              border: 0;
              &:hover {
                color: #354052;
                border: solid 1px #c2c7d0 !important;
                background-color: #ffffff;
              }
            }
          }
        }
      }
      &.user-assets {
        margin-left: 80px;
        min-width: 220px;
        .assets-title {
          user-select: none;
          font-size: 18px;
          font-weight: 500;
          padding-bottom: 7px;
        }
        .assets-item {
          min-width: 100px;
          span {
            font-size: 24px;
            font-weight: 500;
            color: #354052;
          }
        }
        .assets-two {
          min-width: 100px;
          padding-top: 5px;
          span {
            font-size: 12px;
            font-weight: 500;
            color: #9aa1b1;
          }
        }
      }
    }
  }

  .situation-block {
    user-select: none;
    // padding-left: 80px;
    // min-width: 500px;
    display: flex;
    text-align: left;
    font-size: 14px;
    .sub-title {
      color: #354052;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 15px;
    }
    .level-title {
      font-size: 14px;
      color: #9aa1b1;
      font-weight: 400;
    }

    // .cr-explain {
    //   width: 40%;
    //   margin: 0 10px;
    //   border-right: 1px solid #f3f5f7;
    //   margin-right: 50px;

    //   color: #333333;
    // }
    .sub-lis {
      display: flex;
    }
    .level-content {
      display: flex;
      align-items: center;

      margin-top: 16px;
      .iconicon-fail {
        font-size: 17px;
        margin-top: -2px;
      }
    }

    .auth-tag-1,
    .auth-tag-2,
    .auth-tag-3 {
      .at-icon {
        background: url("./../../assets/img/st-icon-1-1.png") no-repeat center
          center;
        background-size: 100% 100%;
        display: inline-block;
        width: 62px;
        height: 62px;
        border-radius: 50%;
        cursor: pointer;
      }
      p {
        line-height: 40px;
        color: #b4bac6;
      }
    }

    .auth-tag-2 .at-icon {
      background-image: url("./../../assets/img/st-icon-2-1.png");
    }

    .auth-tag-3 .at-icon {
      background-image: url("./../../assets/img/st-icon-3-1.png");
    }

    .auth-tag-1.auth-active {
      .at-icon {
        background-image: url("./../../assets/img/st-icon-1.png");
      }
      p {
        color: #27c683;
      }
    }
    .auth-tag-2.auth-active {
      .at-icon {
        background-image: url("./../../assets/img/st-icon-2.png");
      }
      p {
        color: #27c683;
      }
    }

    .auth-tag-3.auth-active {
      .at-icon {
        background-image: url("./../../assets/img/st-icon-3.png");
      }
      p {
        color: #27c683;
      }
    }
  }

  .steps-bar {
    font-size: 14px;
    color: #666;
    position: relative;
    height: 10px;
    margin-bottom: 10px;
    .sr-left {
      position: absolute;
      top: -8px;
      left: 0px;
      color: #354052;
    }
    .sr-right {
      position: absolute;
      top: -8px;
      right: -20px;
      color: #354052;
    }
    ul {
      margin: 0;
      li {
        float: left;
        width: 100px;
        margin: 0 3px;
        border-radius: 30px;
        background: #e7e7e7;
        height: 4px;
        overflow: hidden;
      }
    }
  }
  .sr-1 {
    margin-left: 30px !important;
  }
  .steps-bar-1 {
    .sr-1 {
      background: #f73728;
      margin-left: 35px;
    }
  }
  .steps-bar-2 {
    .sr-1,
    .sr-2 {
      background: #f78700;
    }
  }
  .steps-bar-3 {
    .sr-1,
    .sr-2,
    .sr-3 {
      background: #27c683;
    }
    .sr-right {
      right: -25px;
    }
  }
  .login-content {
    background-color: #fff;
    margin-top: 20px;
    // min-height: 781px;
    padding: 0 20px;
    border-radius: 4px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
    .el-select .el-input.is-focus .el-input__inner {
      border-color: #c61b3d;
    }

    .filter-bar {
      user-select: none;
      height: 68px;
      font-size: 20px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #fff;
      border-bottom: 1px solid #f3f5f7;
      .coin-select {
        background-size: 24px 24px !important;
        .el-input__inner {
          height: 40px;
          padding-left: 50px;
          background-color: transparent;
        }
      }
    }
    .g-table {
      //  min-height: 544px;
      min-height: 554px;
      thead {
        th {
          border: none;
        }
        .cell {
          font-size: 14px;
          font-weight: 400;
          color: rgba(180, 186, 198, 1);
          line-height: 14px;
        }
      }

      // 确认数量
      .confirm-span {
        color: #27c683;
      }

      // 表格行
      tr {
        .el-table__expanded-cell {
          color: #717985;
          line-height: 36px;
          text-align: center;
          background-color: rgba(245, 247, 250, 1);
          padding: 12px 0;
          p {
            span {
              cursor: pointer;
              &:hover {
                color: #0199f1;
              }
            }
          }
          &:hover {
            background-color: rgba(245, 247, 250, 1) !important;
          }
        }
      }

      .el-table__row {
        // height: 60px;

        // td {
        //   border-bottom: none;
        // }
        .el-table__expand-column {
          .cell {
            padding: 0;
          }
        }
        /* 等待中 */
        .status-waiting,
        .status-pending {
          color: #f5a623;
        }
        /* 确认中 */
        .status-confirming,
        .status-cantrade {
          color: #1a53f0;
        }
        /* 成功 */
        .status-success {
          color: #27c683;
        }
        /* 失败 */
        .status-faild {
          color: #f43932;
        }
        /* 取消 */
        .status-canceled {
          color: #0199f1;
        }
        .seeTxid {
          color: #0199f1;
          cursor: pointer;
        }
      }
      .el-table__header {
        .cell {
          height: 15px;
        }
      }
    }
    .pages {
      height: 67px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #fff;
      p {
        color: #aab2c0;
        font-size: 14px;
        padding-left: 10px;
        span {
          color: #354052;
        }
      }
      &.mt20 {
        margin: 0;
        .el-pager li {
          &:hover {
            color: #27c482;
          }
          &.active {
            background-color: #27c482;
          }
          &.active:hover {
            color: #fff;
          }
        }
      }
    }
  }
  .el-avatar {
    user-select: none;
    img {
      width: 48px;
      height: 48px;
    }
  }
}
</style>
